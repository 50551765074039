import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Google Material You App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/google-material-you/"
    metaDescription="With this pre-made template, you can already design your next Android mobile app using the beautiful new design system from the tech giant!"
    description="
    h2:A Google Material You mobile app design template 
    <br/>
    Material You is the latest version of Material Design, the open design system created by Google that quickly became a design standard for the entire tech industry. To empower you to ideate your own app in the vein of Google Material You, our inhouse designers have created this stunning Material You app design template
    <br/>
    h3:Fresh from the design lab
    <br/>
    There is still a lot of mystery surrounding Google Material You, but what we do know is that, as a design system, it looks absolutely stunning. To reflect the chic style of GMU, we created our very own <a:https://uizard.io/templates/mobile-app-templates/>mobile app template</a> to help you take advantage of Google's latest design innovation.
    <br/>
    h3:A new look for Android 12 and beyond
    <br/>
    Google has big plans for Material You. The tech giant foresees their new design system transforming the entire tech industry, maybe even more profoundly than Material Design did. With our pre-made template, you can design your next Android mobile app and take inspiration from Material You to get well ahead of the game. Start <a:https://uizard.io/prototyping/>UI prototyping</a> with Uizard today, for free! 
    "
    pages={[
      "Home screen in light mode",
      "Home screen in dark mode",
      "Widget screen with calendar card, music player card, message card, and footer nav bar",
      "Wallpaper screen with photo categories and footer nav bar",
    ]}
    projectCode="JJLW5Mp3pEUzQzZY4bMY"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Google Material You Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Google Material You Mobile App: dark home screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Google Material You Mobile App: light home screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Google Material You Mobile App: widgets"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Google Material You Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/google-material-you/Google-Material-You-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/google-material-you/Google-Material-You-Home-Dark.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/google-material-you/Google-Material-You-Home-Light.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/google-material-you/Google-Material-You-Widgets.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/google-material-you/Google-Material-You-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
